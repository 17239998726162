<template>
  <div class="studio-upcoming-event-modal">
    <HMModalHeader
      :title="$t('EVENTS.TITLE.EVENT_NOTIFICATION')"
      @close="close"
    />
    <h1 class="mb-4">
      {{ $t('EVENTS.TITLE.STARTING_IN_MINUTES', { minutes: minutesToEvent }) }}
    </h1>
    <hr class="m-0 p-0" />
    <NuxtLink
      class="undecorated-router-link w-100"
      :to="{
        name: 'studio-event-details',
        params: { studio_url: event.shop_url, event_id: event.id },
      }"
      @click.native="close"
    >
      <CompactEvent :event="event" />
    </NuxtLink>
    <p class="mt-4">{{ $t('EVENTS.TEXT.CLICK_BELOW_TO_GO_TO_EVENT') }}</p>
    <HMButton class="w-100 text-center" color="brand">
      <NuxtLink
        class="undecorated-router-link w-100"
        :to="{
          name: 'studio-event-details',
          params: { studio_url: event.shop_url, event_id: event.id },
        }"
        @click.native="close"
      >
        <span class="w-100 text-center">{{
          $t('EVENTS.BUTTON.GO_TO_EVENT')
        }}</span>
      </NuxtLink>
    </HMButton>
  </div>
</template>

<script lang="ts">
import moment from '@/services/moment';
import CompactEvent from '@/components/events/CompactEvent.vue';

export default {
  name: 'StudioUpcomingEventModal',
  components: {
    CompactEvent,
  },
  props: {
    event: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
  computed: {
    minutesToEvent() {
      const now = moment();
      const eventStart = moment(this.event.event_start_datetime);
      const duration = moment.duration(eventStart.diff(now));
      return parseInt(duration.asMinutes());
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      if (this.onClose) this.onClose();
      this.$attrs._close()
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
</style>
